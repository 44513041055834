import React from "react";

import MainLayout from "../layouts/MainLayout";

const HomePage: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col items-start gap-5 p-5">
        <h1 className="text-2xl font-bold text-center">
          Where and when is BCP?
        </h1>
        <p className="text-lg">
          Boston Cube Party will be held the weekend of April 12th and 13th at
          the{" "}
          <a
            href="https://www.cambridgecc.org/"
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
          >
            Cambridge Community Center
          </a>
          .The first draft of each day starts promptly at 10am, so please try to
          arrive 15 minutes early to get yourself settled and make sure you
          don't miss any Cubing!
        </p>
        <h1 className="text-2xl font-bold text-center">How do I register?</h1>
        <p>
          Tickets are live! You can purchase them{" "}
          <a
            href="https://buy.stripe.com/3cs8zp4IN5wT11uaEH"
            target="_blank"
            rel="noreferrer"
            className="text-rose-800 hover:underline"
          >
            here
          </a>
          !
        </p>
        <p>
          If you can't make it to the event, but you want a playmat, you can
          purchase one{" "}
          <a
            href="https://buy.stripe.com/5kAaHxb7b4sP6lO148"
            target="_blank"
            rel="noreferrer"
            className="text-rose-800 hover:underline"
          >
            here
          </a>
          .
        </p>
        <h1 className="text-2xl font-bold text-center">
          How is the BCP Cube tournament structured?
        </h1>
        <p>
          There will be 5 rounds that feed into a top 8 finals draft. There will
          also be an extra draft on Sunday for those who don't make the top 8.
        </p>
        <h1 className="text-2xl font-bold text-center">
          What is the prize structure?
        </h1>
        <p>
          For each pod 3-0'd, you will receive a small commemorative trophy pin.
          Additionally, each player who makes it to the top 8 table will receive
          a Boston Cube Party Top 8 pin. Our tea time champion will be heralded
          as the victor on all the Internets, and we promise to never throw them
          in the harbor. Instead the champion will receive a special
          commemorative trophy that we're pretty excited to show off.
        </p>
        <h1 className="text-2xl font-bold text-center">
          Which Cubes will be at BCP?
        </h1>
        <p>
          This year we're trying to select a unique blend of Cubes from both New
          England and around the country so all participants have access to
          exotic options that they normally may not have access to. There is no
          formal process to get your Cube in the event. You can tweet us at{" "}
          <a
            href="https://twitter.com/BostonCubeParty"
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
          >
            @bostoncubeparty
          </a>{" "}
          if you're interested in having your Cube included in the event, but we
          will be very selective on the Cubes that we include in the event.
        </p>
        <p>
          You can find the list of Cubes that have been selected for the event{" "}
          <a
            href="/cubes"
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
          >
            here
          </a>
          .
        </p>
        <h1 className="text-2xl font-bold text-center">
          Can I bring in outside food/drink?
        </h1>
        <p>
          There will be no food served at the event, but you can bring your own
          food or use a delivery service. The Cambridge Community Center is
          located a very short walk to Central Square, where there is a variety
          of great food options, in terms of sit-down restaurants, cafes, and
          bars. There is no smoking or vaping inside or within 15' of the venue.
          No alcohol will be sold, but you may bring your own. If you do not
          drink responsibly or if you disrespect the space in any other way, you
          will be asked to leave.
        </p>
        <h1 className="text-2xl font-bold text-center">
          Are tickets refundable?
        </h1>
        <p>
          Tickets are refundable up to 14 days before the event. March 28th,
          2025, is the last day we can process a refund.
        </p>
        <h1 className="text-2xl font-bold text-center">
          If I have other questions, how do I contact BCP?
        </h1>
        <p>
          You can contact us in our{" "}
          <a
            href="https://discord.gg/WUfUgqm9m9"
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
          >
            Discord server
          </a>
          , or on twitter at{" "}
          <a
            href="https://twitter.com/BostonCubeParty"
            target="_blank"
            rel="no-referrer noreferrer"
            className="text-rose-800 font-medium hover:underline"
          >
            @bostoncubeparty
          </a>
          .
        </p>
      </div>
    </MainLayout>
  );
};

export default HomePage;
